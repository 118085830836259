import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import commonReducers from '../../common/reducers';
import postCount from '../../common/store/post-count/post-count-reducer';
import categories from '../../common/store/categories/categories-reducer';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};
  const getStore = () => p.store;

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    wixData: wixDataReducer,
    postCount,
    categories,
  });

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}
