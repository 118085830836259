import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import { SECTION_CATEGORY, getLastPage } from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getTagListItems } from '../get-tag-list-items';
import { getTagUrl } from '../get-tag-url';
import { getMultilingualQueryParam } from '../get-multilingual-query-param';
import { createTagPageUrl } from '../navigation';
import { getPaginationUrls } from './get-pagination-item-data';

export const generateTagSEOTags = ({ sectionUrl, postPageSectionUrl, tag, state, page, posts, multilingual }) => {
  const tagUrl = getTagUrl(sectionUrl, tag.slug);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const multilingualQueryParam = getMultilingualQueryParam(multilingual);
  const pagination = {
    ...getPaginationUrls(tagUrl, page, lastPage, state, multilingualQueryParam),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    name: tag.label,
    title: tag.label,
    fullUrl: createTagPageUrl(sectionUrl, tag.slug, page, multilingualQueryParam),
    listItems: getTagListItems(posts, postPageSectionUrl),
    items: {
      numberOfItems: posts.length,
    },
    created: tag.createdDate,
    postCount: tag.postCount,
    language: tag.language,
    pagination,
  };

  return {
    itemType: ITEM_TYPES.BLOG_TAGS,
    itemData: { tag: itemData },
  };
};
